@import '../../styles/vars';

.task-page {
    padding-top: 0 !important;

    .task-header {
        width: 1073px;
        height: 75px;
        position: fixed;
        z-index: 1000;
        padding: 55px 0;
        background-color: $brand-tertiary;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-nav {
            h1 {
                a {
                    color: $brand-primary;

                    &:hover {
                        color: $brand-secondary;
                    }
                }
            }

            h2 {
                span.topic {
                    color: $brand-secondary;
                }

                &.sub {
                    a {
                        color: $brand-primary;
                        font-weight: 400;

                        &:hover {
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }

        button.primary {
            position: relative;
            border-radius: 10px !important;
            border: none !important;
            display: flex;
            align-items: center;
            padding: 12px !important;

            &.incomplete {
                background-color: $dataviz-7 !important;
            }

            &.complete {
                background-color: rgba(64, 183, 176, 0.6) !important;

                .MuiButtonBase-root {
                    color: $brand-secondary;
                }
            }

            .MuiButtonBase-root {
                padding: 0;
                margin-right: 10px;
                color: $white;
                position: relative;
                top: 1px;
            }
        }
    }

    .content-wrapper {
        margin: 185px 0 0 0;
        width: 100%;
        background-color: $white;
        border-radius: 10px;

        .content-header {
            padding: 35px 30px 35px 50px;

            h1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;

                a {
                    margin: 0 0 0 auto;
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: $brand-secondary;

                    &:hover {
                        text-decoration: none;
                    }

                    img {
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }

        .task-notes {
            padding: 25px;
            background-color: $brand-secondary;
            position: sticky;
            top: 0;
            z-index: 1000;

            h1 {
                color: $white;
                padding: 0 0 20px 25px;
            }

            textarea {
                width: 94.5%;
                padding: 25px 25px 25px 25px;
                font-family: 'Sen';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                border-radius: 10px;
                border: 1px solid white;

                &::placeholder {
                    color: rgba(38, 39, 63, 0.4);
                }
            }
        }

        .post-content.blog-content {
            position: relative;

            .fusion-title {
                display: none;
            }

            a {
                text-decoration: none;
                color: $brand-secondary;
                transition-property: color,background-color,border-color;
                transition-duration: .2s;
                transition-timing-function: linear;

                &:hover {
                    color: #f86535;
                }
            }

            h1 {
                font-size: 54px;
                color: $brand-primary;
                font-weight: 400;

                &.title-heading-left {
                    margin: 20px 0 60px 0 !important;
                }
            }

            h2 {
                font-size: 42px;
                color: $brand-primary;
                font-weight: 400;
            }

            h3 {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                color: $brand-primary;

                &.content-box-heading {
                    font-weight: 400 !important;
                }
            }

            h4 {
                font-size: 28px;
                color: $brand-primary;
                font-weight: 400;
                margin: auto;
            }

            h5 {
                font-size: 24px;
                color: $brand-primary;
                font-weight: 400;
            }

            p, ul, ol {
                font-family: Sen;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                color: $brand-primary;
            }

            img {
                border-style: none;
                vertical-align: top;
                max-width: 100%;
                height: auto;
            }

            table {
                border: 2px solid #e2e2e2;
                padding: 5px;
                border-collapse: collapse;
                border-spacing: 0;
                max-width: 100%;
                background-color: transparent;

                th, td {
                    border: 2px solid #e2e2e2;
                    padding: 5px;
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .wp-caption-text {
                font-style: italic;
            }

            .fusion-layout-column.fusion_builder_column.fusion-builder-column-6.fusion-flex-column.fusion-flex-align-self-stretch {
                display: none;
            }

            .fusion-meta-tb.fusion-meta-tb-1.floated {
                display: none;
            }

            .fusion-builder-row.fusion-builder-row-inner.fusion-row.fusion-flex-align-items-stretch.fusion-flex-align-content-flex-start.fusion-flex-content-wrap {
                display: none;
            }

            .fusion-sharing-box {
                display: flex;
                font-size: 24px;
                margin: 50px 0 0 50px;
                padding-bottom: 50px;
                align-items: center;

                h4 {
                    padding-right: 40px;
                }
            }

            .fusion-social-networks {
                justify-content: flex-start;
                flex-grow: 100;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                i {
                    margin-right: 15px;
                    width: 35px;
                    height: 35px;
                    border-radius: 50% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    top: 1px;
                }
            }

            .fusion-flex-container {
                display: flex;
                flex-wrap: wrap;

                .fusion-content-boxes {
                    margin-bottom: 40px !important;

                    .content-box-wrapper {
                        padding: 35px;
                        position: relative;

                        .heading-with-icon {
                            display: table;
                            width: auto;
                            overflow: hidden;
                            margin-bottom: 15px;

                            .icon {
                                display: table-cell;
                                vertical-align: middle;
                                float: none;

                                span {
                                    margin-right: 20px;
                                    display: inline-block;

                                    i {
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        ul {
                            li>span {
                                font-weight: 300 !important;
                            }
                        }
                    }

                    &:last-child ul {
                        color: #FFF;
                    }
                }

                .alignleft {
                    display: inline;
                    float: left;
                    margin-right: 30px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .alignright {
                    display: inline;
                    float: right;
                    margin-left: 15px;
                }

                .fusion_builder_column_1_2 {
                    width: 50% !important;
                    float: left;
                }

                .fusion_builder_column_1_4 {
                    width: 40%;
                    min-width: 25%;
                    background-color: $brand-primary;

                    .fusion-text.fusion-text-16 p {
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        color: $white;
                        margin: 35px 40px 15px 40px;
                    }

                    .awb-toc-el__content {
                        ul {
                            margin: 0 23px 35px 23px !important;
                            color: $dataviz-1;
                            width: 75%;

                            li {
                                margin: 0 0 20px;

                                a {
                                    color: $dataviz-1;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                .fusion_builder_column_1_1 {
                    padding: 75px 50px 0 50px;
                }

                .fusion_builder_column_4_5 {
                    display: flex;
                    width: 80% !important;
                    margin-top: 0px;
                    margin-bottom: 20px;
                }

                .fusion-builder-column-6 > .fusion-column-wrapper {
                    margin-right: 0;
                }

                .fusion-builder-row.fusion-row.fusion-flex-align-items-flex-start.fusion-flex-content-wrap {
                    background-color: #f4f6f8;
                    display: flex;

                    .fusion-text.fusion-text-16 {
                        p {
                            margin: 25px 45px;
                        }
                    }

                    .fusion-column-wrapper.fusion-column-has-shadow.fusion-flex-justify-content-flex-start.fusion-content-layout-column {
                        width: 95%;
                        display: flex;
                        flex-direction: column;
                        padding: 0 0 35px 0;

                        .fusion-column-wrapper.fusion-column-has-shadow.fusion-flex-justify-content-flex-start.fusion-content-layout-row {
                            .fusion-image-element {
                                margin: 30px 15px 0 50px;
                            }
                        }

                        .fusion-layout-column.fusion_builder_column_inner.fusion-builder-nested-column-4.fusion-flex-column {

                            p {
                                color: $brand-primary;
                                font-size: 24px;
                                font-weight: 700;
                                margin: 40px 0 0 0;
                            }
                        }

                        ul, ol {
                            margin: 15px 0 15px 50px;

                            ul {
                                margin: 15px 0;
                            }
                        }
                    }
                }

                .fusion-builder-row.fusion-row.fusion-flex-align-items-stretch.fusion-flex-content-wrap {
                    margin: 0 !important;

                    .fusion-layout-column.fusion_builder_column.fusion-builder-column-10.fusion_builder_column_1_1.fusion-flex-column {
                        padding: 75px 50px 0 50px;
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .task-page {
        .task-header {
            padding: 0;
            position: static;

            h1 { display: none; }

            h2.sub {
                margin: 0;

                a {
                    display: block;
                    margin: 25px 20px;
                    padding: 0;
                    font-size: 18px;
                    color: #B3B3B3 !important;
                    line-height: 17px;
                }

                span:nth-child(2) {
                    display: none;
                }

                span.topic {
                    display: none;
                }
            }

            button.primary {
                position: absolute;
                top: 190px;
                left: 0;
                right: 0;
                width: fit-content;
                margin-inline: auto;
            }
        }

        .content-wrapper {
            margin: 0;
            border-radius: 0;

            .content-header {
                padding: 25px 20px 0 20px;
                height: 250px;

                h1 {
                    flex-direction: column;
                    width: 100%;

                    a.toggle-notes {
                        position: absolute;
                        top: 275px;
                    }
                }
            }

            .task-notes {
                padding: 22px 20px;

                h1 {
                    display: none;
                }

                textarea {
                    width: 87.5%;
                    padding: 15px;
                    font-size: 12px;
                    line-height: 14px;
                    min-height: 110px;
                }
            }

            .post-content.blog-content {
                .fusion-flex-container .fusion-builder-row.fusion-row.fusion-flex-align-items-flex-start.fusion-flex-content-wrap {
                    flex-direction: column-reverse;
                }

                .fusion-flex-container .fusion_builder_column_1_4 {
                    width: 100%;
                }

                .fusion-builder-row.fusion-builder-row-inner.fusion-row.fusion-flex-align-items-flex-start.fusion-flex-content-wrap {
                    flex-direction: row;
                    align-items: center;
                    margin: 30px 0 0 20px !important;

                    .fusion-image-element {
                        margin: 0 !important;
                    }

                    .fusion-text p {
                        font-weight: bold;
                        margin-left: 20px;
                        font-size: 24px;
                    }
                }

                .fusion-sharing-box {
                    min-height: 100px;
                    padding-bottom: 150px;
                }
            }
        }
    }
}
