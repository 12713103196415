/*
 * CreateAccount.scss
 */

.create-account {
    p, span {
        display: block;
        text-align: center;
    }

    h1 {
        font-size: 40px;
        color: #378088;
        text-align: center;
    }

    .account-plans {
        display: flex;
        margin: 150px auto;

        .plan {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-style: italic;
                font-size: 18px;
                text-align: center;
            }
        }
    }
}
