@import '../../styles/vars';

.MuiSnackbar-root {
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100%;

    .MuiAlert-root {
        width: 100%;
        height: 115px;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Sen';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $white;
    }

    .MuiAlert-standardSuccess {
        background-color: #7CD4B7;

        .MuiAlert-icon {
            color: #48AA8A;

            img {
                width: 40px;
                height: 40px;
                filter: brightness(0.7);
            }
        }
    }

    .MuiAlert-standardError {
        background-color: #ffaba1;
    }
}
