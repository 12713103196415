@import '../../styles/vars';

.downloading {
    position: absolute;
    top: 0px;
    z-index: 1000;
    width: 100%;
    height: 100px;
    background: #7CD4B7;
    display: flex;
    justify-content: center;
    align-items: center;

    div.status {
        display: flex;
        align-items: center;

        img {
            width: 35px;
            height: 35px;
            filter: brightness(0.85);
            margin-right: 20px;
            animation: spin 2s linear infinite;

            @keyframes spin {
                100% {
                    -webkit-transform: rotate(360deg);
                    transform:rotate(360deg);
                }
            }
        }

        h1 {
            color: $white;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }
    }
}

.budget-planner {
    a {
        color: $brand-secondary;
    }

    .sticky-header {
        position: fixed;
        top: 0;
        width: 1073px;
        height: 150px;
        padding: 35px 0 0 0;
        background: $brand-tertiary;
        z-index: 10000;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        transition: 0.2s;

        h1 {
            margin: 0;
        }

        .MuiToggleButtonGroup-root {
            position: absolute;
            top: 59px;
            left: 370px;
            height: 36px;
            background: transparent;

            .MuiButtonBase-root {
                background-color: $white;
                color: #B3B3B3;
                width: 120px;
                height: 36px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            }

            .MuiToggleButtonGroup-grouped {
                border-radius: 0 !important;

                &:first-child {
                    border-radius: 20px 0px 0px 20px !important;
                }

                &:last-child {
                    border-radius: 0 20px 20px 0 !important;
                }
            }

            .MuiToggleButton-root.Mui-selected {
                background-color: $brand-primary;
                color: $white;
            }
        }

        .help {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                display: inline-block;
                margin: 0 10px 0 0;
                color: $brand-secondary;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }

        h2 {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 5px 0;
        }
    }

    section {
        background-color: $white;
        border-radius: 10px;
        margin: 95px 0 25px 0;

        &:nth-of-type(1) {
            margin-top: 150px;
        }

        .subsection {
            padding: 30px;
            color: $brand-primary;
            position: relative;

            &:last-child {
                border-radius: 0 0 10px 10px;
            }

            &.alt {
                background-color: #F4F6F8;
            }

            &.update-month {
                border-radius: 10px 10px 0 0;
            }

            h3 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $brand-primary;
                display: flex;
                margin: 0 0 15px 0;

                span {
                    margin: 0 0 0 5px;
                    position: relative;
                    top: 6px;
                }
            }

            h4 {
                font-weight: 400;
                font-size: 14px;
                margin: 20px 0;

                span {
                    position: relative;
                    top: -2px;
                }
            }

            span.icon-button {
                cursor: pointer;
            }

            input[name='plan-name'] {
                border: 1px solid $brand-primary;
                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                border-radius: 5px;
                width: 250px;
                padding: 5px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
            }

            .start-end-date {
                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    color: $brand-primary;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 0 15px 0 0;
                }

                select {
                    border: 1px solid $brand-primary;
                    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                    border-radius: 5px;
                    width: 115px;
                    padding: 5px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary;

                    &.update-month {
                        width: 200px !important;
                        margin: 0 10px 0 0;
                    }
                }

                .react-datepicker-wrapper {
                    margin-right: 25px;
                    width: 150px;

                    .datepicker {
                        width: 125px;
                        font-weight: 400;
                    }
                }
            }

            ul.accounts {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;

                .loading {
                    bottom: 15px;
                    top: auto;
                    left: 33px;
                }

                li {
                    display: flex;
                    align-items: flex-start;
                    margin: 0 25px 10px 0;

                    .MuiButtonBase-root {
                        padding: 0 4px 0 0;
                        color: $brand-secondary;
                    }

                    label {
                        color: $brand-primary;
                        position: relative;
                        top: 2px;
                    }
                }
            }

            .table-and-summary {
                label.average {
                    box-shadow: none;
                    padding: 0;
                    color: $brand-secondary !important;
                }

                .summary {
                    text-align: right;

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $brand-primary;
                    }
                }
            }

            .notification-bar {
                width: 775px;
                padding: 10px 20px;
                margin: 35px 0 10px 0;
                background-color: $brand-primary;
                border-radius: 10px;
                display: flex;
                align-items: center;

                .badge {
                    margin-right: 10px;
                }

                p {
                    color: $white;
                    font-size: 14px;
                    margin: 0;
                }
            }

            .download-transactions {
                display: flex;
                align-items: center;
                height: 40px;
                margin: 25px 0;

                button.primary {
                    width: 130px;
                    float: none;
                    margin: 0;

                    &.disabled {
                        background-color: #B3B3B3 !important;
                        border: 1px solid #B3B3B3 !important;
                        cursor: auto;
                        margin-right: 5px;
                    }
                }

                span.text {
                    font-size: 14px;
                    padding: 0 10px;
                }
            }

            .out-of-month-notice {
                width: calc(100% - 40px);
                padding: 10px 20px;
                margin: 25px 0;
                background-color: #FCE5E7;
                border-radius: 10px;
                display: flex;
                align-items: center;

                .badge {
                    margin-right: 10px;
                }

                p {
                    color: $brand-primary;
                    font-size: 16px;
                    margin: 0;
                }
            }

            .account-transactions {
                padding: 15px 25px;
                background-color: $brand-tertiary;
                margin: 0 0 25px 0;
                border-radius: 15px;
                overflow: hidden;

                &.collapsed {
                    height: 35px;
                    min-height: auto;
                }

                .header {
                    display: flex;
                    align-items: center;
                    height: 35px;

                    h5 {
                        font-size: 20px;
                        font-weight: 700;
                        margin: 0;
                        display: flex;
                        align-items: center;

                        span.icon-button {
                            margin-left: 10px;
                        }
                    }

                    .last-sync {
                        margin: 0 20px 0 auto;
                    }

                    svg {
                        font-size: 35px;
                        cursor: pointer;
                    }
                }

                .transaction-table {
                    .header {
                        margin: 15px 0 5px 0;

                        .column {
                            font-size: 14px;

                            span.icon-button {
                                position: relative;
                                top: -2px;
                                margin-left: 5px;
                            }

                            &.title {
                                width: 47%;
                            }

                            &.category {
                                width: 43%;
                                display: flex;
                                align-items: center;

                                .dot {
                                    width: 12px;
                                    height: 12px;
                                    margin: 0 5px 0 10px;
                                }
                            }

                            &.ignore {
                                width: 10%;
                            }
                        }
                    }

                    .body {
                        .row {
                            height: 25px;
                            display: flex;
                            align-items: center;
                            background-color: $white;
                            padding: 10px 15px 10px 25px;
                            position: relative;

                            &:nth-child(even) {
                                background-color: #F4F6F8;
                            }

                            .dot.new-transaction {
                                position: absolute;
                                left: 10px;
                                top: 20px;
                                width: 8px;
                                height: 8px;
                                background-color: #E5626A;
                            }

                            .field {
                                font-size: 14px;

                                &.pending {
                                    font-weight: bold;
                                    color: $brand-primary;
                                }

                                &.date {
                                    width: 15%;
                                }

                                &.name {
                                    width: 30%;
                                    padding-right: 5%;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }

                                &.amount {
                                    width: 10.7%;
                                }

                                &.category {
                                    width: 32%;
                                    display: flex;
                                    align-items: center;

                                    .pending {
                                        font-weight: bold;
                                        color: $brand-primary;
                                    }

                                    .dot {
                                        width: 12px;
                                        height: 12px;
                                    }

                                    input[type=text] {
                                        margin: 0;
                                    }
                                }

                                &.ignore {
                                    width: 6%;

                                    .MuiButtonBase-root {
                                        padding: 0;
                                        color: $brand-secondary;
                                    }
                                }
                            }

                            &.ignore {
                                .field {
                                    color: #B3B3B3 !important;
                                }
                            }
                        }
                    }

                    input[type=text] {
                        background: transparent;
                        width: 200px;
                        padding: 5px;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        font-family: 'Sen';
                        color: $brand-secondary;
                        margin: 0 0 0 5px;
                        cursor: pointer;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        &::placeholder {
                            color: $brand-secondary;
                        }
                    }
                }
            }

            #manualTransactionsTable {
                .amount {
                    width: 125px;
                    margin-right: 50px;
                }

                .field.category {
                    width: 230px;
                    display: flex;
                    align-items: center;
                    padding: 0 0 0 2px;

                    .dot {
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                    }

                    input[type=text] {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        color: $brand-secondary !important;
                        cursor: pointer;
                        padding: 5px 5px 5px 0;

                        &::placeholder {
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }

        .transaction-summary {
            margin: 25px 0 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            span {
                font-weight: 700;
            }

            .summary-item {
                display: flex;
                align-items: center;
                margin-right: 10px;

                span.dot {
                    margin: 1px 5px 0 10px;
                }

                label {
                    font-weight: 700;
                }

                span.amount {
                    font-weight: 400;
                }
            }
        }

        &.balance-check {
            .subsection {
                .summary {
                    .summary-row {
                        display: flex;
                        align-items: center;
                        padding: 12px 50px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin: 0 15px 0 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: $brand-primary;

                            .income {
                                color: $dataviz-1;
                            }

                            .savings {
                                color: $dataviz-3;
                            }

                            .spending {
                                color: $dataviz-7;
                            }
                        }
                    }
                }

                .pie-chart {
                    padding: 0 40px 0 30px;
                    display: flex;
                    position: relative;

                    .legend {
                        width: 25%;
                        margin: 60px 0;

                        .legend-row {
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            margin: 0 0 38px 0;

                            label {
                                font-weight: 700;
                                font-size: 21px;
                                line-height: 25px;
                                margin: 0 12px 0 0;

                                &.income {
                                    color: $dataviz-1;
                                }

                                &.savings {
                                    color: $dataviz-3;
                                }

                                &.spending {
                                    color: $dataviz-7;
                                }
                            }
                        }
                    }

                    .balance-line {
                        width: 100%;
                        height: 20px;
                        text-align: right;
                        position: absolute;
                        left: 0;
                        display: flex;
                        align-items: center;

                        .line {
                            width: 100%;
                            border-bottom: 2px dashed #B3B3B3;
                        }

                        label {
                            width: 12%;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: #B3B3B3;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }
        }

        &.summary-review {
            position: relative;
            scroll-margin-top: 200px;

            span.badge {
                position: absolute;
                top: -12px;
                right: -12px;
            }

            a[name=review] {
                text-decoration: none;
            }

            a.toggle-notes {
                position: absolute;
                right: 25px;
                top: 75px;
                display: flex;
                align-items: center;

                img {
                    position: relative;
                    top: 2px;
                    margin-left: 10px;
                }
            }

            #summaryNotes {
                z-index: 1000;
                top: -25px !important;
                left: 169px !important;

                .note {
                    width: 735px;
                    height: 200px;
                    background: #FEFFC1;
                    border: 1px solid #B3B3B3;
                    border-width: 0 0 1px 1px;
                    border-radius: 0 0 0 30px;
                    box-shadow: -3px 4px 4px rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;

                    textarea {
                        width: calc(100% - 30px);
                        height: 62%;
                        border: none;
                        background: transparent;
                        outline: none;
                        resize: none;
                        box-shadow: none;
                        overflow: hidden;
                        font-size: 18px;
                        color: #B3B3B3;
                        padding: 10px 15px;
                    }
                }
            }

            .subsection.review {
                padding: 0;
                background-color: $brand-primary;

                .content-wrapper {
                    border-radius: 0 0 10px 10px;
                }

                &:nth-of-type(even) {
                    .content-wrapper {
                        background-color: #F4F6F8;
                    }
                }

                &:nth-of-type(odd) {
                    .content-wrapper {
                        background-color: $white;
                    }

                    .pacing-bar {
                        background-color: #F4F6F8;
                    }
                }

                &:nth-of-type(odd) {
                    .pacing-bar {
                        background-color: #F4F6F8;
                    }
                }

                .category-summary {
                    display: flex;
                    align-items: center;
                    padding: 30px 30px 0 30px;

                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 25px;
                    }

                    label {
                        width: 160px;
                        font-size: 21px;
                        font-weight: 700;
                        margin-right: 25px;
                    }

                    .pacing-bar {
                        width: 770px;
                    }
                }

                .category-sub-summary {
                    padding: 10px 30px 0 275px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 30px;
                        margin-right: 15px;
                    }

                    .sub-summary-text {
                        font-size: 18px;
                        font-weight: 700;
                        margin-right: 50px;
                        flex-grow: 1;
                    }

                    .sub-summary-percent {
                        font-size: 18px;
                        font-weight: 700;
                        margin-right: 15px;

                        &.on-track { color: #7CD4B7; }
                    }

                    .sub-summary-goal {
                        font-size: 16px;
                        font-weight: 700;
                        color: #B3B3B3;

                        span {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

                .category-summary-table {
                    width: 650px;
                    padding: 40px 30px 0 275px;

                    .header, .row {
                        display: flex;
                        align-items: center;
                        padding: 0 0 20px 0;
                        position: relative;

                        .column, .field {
                            font-size: 14px;
                            font-weight: 700;

                            &.icon {
                                width: 45px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            &.source {
                                width: 165px;
                                padding-right: 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            &.actual, &.goal {
                                width: 90px;
                                padding-right: 10px;
                                color: $brand-primary !important;
                            }

                            &.achievement {
                                width: 250px;
                                display: flex;
                                align-items: center;

                                .pacing-bar {
                                    width: 200px !important;
                                    height: 10px;

                                    .pace {
                                        height: 10px;
                                    }
                                }

                                span {
                                    margin-left: 5px;
                                }
                            }

                            &.withdrawal {
                                position: absolute;
                                right: -140px;
                            }
                        }

                        .field {
                            font-weight: 400;
                        }
                    }
                }

                .summary-total {
                    padding: 45px 30px 40px 0;
                    text-align: right;
                    border-radius: 0 0 30px 30px;

                    p {
                        font-weight: 700;
                        font-size: 18px;
                    }
                }

                .mark-complete-bar {
                    &.income { background-color: $white; }
                    &.savings { background-color: #F4F6F8; }
                    &.spending { background-color: $brand-tertiary; }

                    .content-wrapper {
                        height: 65px;
                        background-color: $brand-primary;
                        border-radius: 0 0 10px 10px;
                        padding: 0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $white;

                        .badge {
                            position: relative !important;
                            top: 0; right: 0;
                            margin-right: 15px;
                        }

                        p {
                            font-size: 18px;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }

        &.plan-pacing {
            height: 680px;
            position: relative;

            .background {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                z-index: 10;
                position: absolute;

                .header {
                    width: 30%;
                    height: calc(100% - 20px);
                    padding: 20px 0 0 30px;
                    background-color: $white;
                    border-radius: 10px 0 0 10px;

                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 29px;
                        color: $brand-primary;
                        display: flex;
                        margin: 0 0 10px 0;

                        span {
                            margin: 0 0 0 5px;
                            position: relative;
                            top: 6px;
                        }
                    }

                    h4 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-weight: 400;
                        }

                        .pacing-legend {
                            display: inline-block;
                            width: 60px;
                            border-top: 2px dashed $brand-secondary;
                            height: 2px;
                            margin: 0 10px;
                        }
                    }
                }

                .months {
                    width: 68%;
                    height: 100%;
                    display: flex;
                    background-color: #F4F6F8;
                    border-radius: 0 10px 10px 0;

                    .month {
                        border: 2px solid $white;
                        border-width: 2px 2px 2px 0;
                        padding: 60px 12px 0 12px;
                        text-align: center;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #9D9D9D;

                        &:first-child {
                            border-left: 2px solid $white;
                        }

                        &:last-child {
                            border: 2px solid $white;
                            border-width: 2px 2px 2px 0;
                            border-radius: 0 10px 10px 0;
                        }

                        &.active {
                            color: $brand-secondary;
                            font-weight: 700;
                            background-color: #E6E9ED;
                        }
                    }
                }
            }

            .foreground {
                width: 100%;
                display: flex;
                flex-direction: column;
                z-index: 100;
                position: absolute;
                top: 170px;

                .pacing-section {
                    width: 100%;
                    height: 170px;
                    display: flex;
                    position: relative;

                    .pacing-metadata {
                        background-color: #F4F6F8;
                        width: calc(30% - 15px);
                        padding: 5px 15px 25px 25px;
                        border-left: 2px solid $white;

                        &.even {
                            background-color: $white;
                        }

                        &.spending {
                            border-radius: 0 0 0 10px;
                        }

                        .category-info {
                            display: flex;
                            align-items: center;
                            margin: 0 0 25px 0;

                            img {
                                width: 23px;
                                height: 23px;
                                margin-right: 10px;
                            }

                            label {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 19px;
                                color: $brand-primary;
                                margin-right: 25px;
                                width: 104px;
                            }

                            select {
                                width: 135px;
                                height: 36px;
                                background-color: $white;
                                border: 1px solid $brand-primary;
                                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                                border-radius: 5px;
                                padding: 8px 10px;
                            }
                        }

                        .summary {
                            display: flex;
                            align-items: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }

                            span {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                color: $brand-primary;
                            }
                        }
                    }

                    .achievement-goal {
                        position: absolute;
                        width: 66%;
                        left: 33%;
                        top: 50px;
                        display: flex;
                        align-items: center;
                        padding: 5px 0 0 5px;

                        label {
                            font-size: 18px;
                            font-weight: 700;

                            span {
                                font-size: 14px;
                            }
                        }

                        .achievement.on-track { color: #7CD4B7; }
                        .achievement.off-track { color: $status-red; }

                        .goal {
                            margin: 0 0 0 auto;
                        }
                    }
                }
            }

            .today-container {
                width: 66.8%;
                height: 585px;
                margin-left: 33%;
                z-index: 1000;
                position: absolute;
                top: 95px;

                .marker {
                    width: 2px;
                    border-left: 2px dashed $brand-secondary;
                    height: 99%;
                    position: absolute;
                }
            }
        }

        &.monthly-comparison {
            padding: 0;

            .comparison-header {
                display: flex;
                align-items: center;
                padding: 30px;

                h3 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $brand-primary;
                    display: flex;
                    margin: 0;

                    span {
                        margin: 0 0 0 5px;
                        position: relative;
                        top: 6px;
                    }
                }

                .category-selects {
                    margin: 0 0 0 auto;
                    display: flex;
                    align-items: center;

                    .dot {
                        position: relative;
                        top: 3px;
                    }

                    select {
                        width: 135px;
                        height: 36px;
                        background-color: $white;
                        border: 1px solid $brand-primary;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        padding: 8px 10px;
                        margin-right: 15px;

                        &:nth-of-type(3) {
                            margin-right: 0;
                        }
                    }
                }
            }

            .chart {
                padding: 0 30px 25px 30px;

                .recharts-tooltip-cursor {
                    position: relative;
                    top: -20px;
                }
            }

            .legend {
                width: calc(100% - 40px);
                height: 20px;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background-color: $brand-primary;
                border-radius: 0 0 10px 10px;

                ul {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        margin-right: 35px;
                        display: flex;
                        align-items: center;
                        font-size: 16px;

                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        &.cashflow-breakdown {
            .breakdowns {
                margin: 25px 0 0 0;
                display: flex;
                justify-content: space-between;

                .breakdown-section {
                    width: 30%;
                    background-color: #F4F6F8;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;

                    h2 {
                        margin: 20px 0 0 20px;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: $brand-primary;
                        display: flex;
                        align-items: center;

                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 15px;
                        }
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $brand-primary;
                        margin: 10px 0 10px 20px;
                    }

                    .pie-chart {
                        width: 150px;
                        margin: 20px auto;
                    }

                    .pie-tooltip {
                        background-color: #FFF;
                        border: 2px solid #378088;
                        border-radius: 25px;
                        padding: 10px;
                        box-shadow: 0px 10px 20px #000000;
                    }

                    .breakdown-legend {
                        width: 100%;
                        height: 53px;
                        background-color: $brand-primary;
                        border-radius: 0px 0px 10px 10px;

                        .legend-contents {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            span.icon {
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }

                            label, span.amounts {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                margin-right: 5px;
                            }

                            span.amounts {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    button.primary.larger {
        float: right;
        margin: 0 0 25px 0;
    }

    .react-datepicker-wrapper {
        width: 120px;

        .datepicker {
            width: 95px;
            padding: 8px 10px;
            border: 1px solid rgba(38, 39, 63, 0.5);
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
            border-radius: 5px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $brand-primary;

            &::placeholder {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #B3B3B3;
            }
        }
    }
}

.dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;

    &.square {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border-radius: 0;
    }

    &.income { background-color: $dataviz-1; }
    &.savings { background-color: $dataviz-3; }
    &.spending { background-color: $dataviz-7; }
}

.pacing-bar {
    width: auto;
    height: 50px;
    background-color: $white;
    position: relative;
    border-radius: 0px 30px 30px 0px;

    &.review {
        width: calc(67% + 1px);
    }

    .pace {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 0px 30px 30px 0px;
        position: absolute;
        z-index: 10;

        label.total {
            font-weight: 700;
            font-size: 18px;
            position: relative;
            right: -125px;
            padding-left: 10px;

            &.off-track {
                color: $status-red;
            }

            &.on-track {
                color: #7CD4B7;
            }

            &.inside {
                color: $white;
                padding: 0;
                right: 0px;
                text-align: right;
            }

            &.zero {
                right: 5px;
            }
        }

        &.off-track {
            background-color: $status-red;
        }

        &.on-track {
            background-color: #7CD4B7;
        }

        &.full {
            padding-right: 2px;
            width: 100% !important;
        }

        &.zero {
            justify-content: flex-start;
        }
    }
}

.MuiAutocomplete-popper {
    width: 250px !important;
    left: 1px !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: $brand-secondary !important;
}

.video-lightbox {
    .MuiDialog-paper {
        width: auto !important;
        max-width: 100% !important;
    }
}

@media (max-width: 1439px) {
    .budget-planner {
        .sticky-header {
            width: 100%;
            height: auto;
            flex-direction: column;
            padding-top: 70px;

            .MuiToggleButtonGroup-root {
                position: static;
                display: block;
                width: 300px;
                height: 50px;
                margin: 15px auto;

                .MuiButtonBase-root {
                    width: 100px;
                    height: 50px;
                }

                .MuiToggleButtonGroup-grouped {
                    border-radius: 0 !important;

                    &:first-child {
                        border-radius: 10px 0px 0px 10px !important;
                    }

                    &:last-child {
                        border-radius: 0 10px 10px 0 !important;
                    }
                }
            }

            .help {
                display: none;
            }

            h1 {
                width: 95%;
                text-align: left;
                margin: 10px 0 10px 10px;
            }

            h2 {
                padding: 0 0 15px 5px;
                font-size: 14px;
                width: 93%;
            }
        }

        section {
            max-width: 100%;
            overflow: hidden;
            border-radius: 0 !important;

            &:nth-of-type(1) {
                margin-top: 221px;
            }

            .subsection {
                padding: 0;

                input[name=plan-name] {
                    margin: 0 0 30px 30px;
                }

                .start-end-date {
                    flex-direction: column;

                    .date-wrapper {
                        height: 70px;

                        label {
                            width: 50px;
                            text-align: right;
                            display: inline-block;
                        }

                        .react-datepicker-wrapper {
                            .datepicker {
                                width: 170px;
                            }
                        }
                    }
                }

                ul.accounts {
                    padding: 0 0 30px 30px;
                    flex-direction: column;
                }

                h3 {
                    font-size: 18px;
                    padding: 30px 30px 0 30px;
                }

                h4 {
                    padding: 0 30px;
                }

                .table-and-summary {
                    .summary {
                        text-align: left;
                        padding: 30px;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        p {
                            margin-bottom: 50px;
                        }

                        span.summary-amount {
                            position: absolute;
                            top: 89px;
                            left: 20px;
                        }
                    }
                }

                .pie-chart {
                    flex-direction: column;

                    .balance-line {
                        width: 88% !important;
                        left: 5px !important;
                    }

                    .legend {
                        width: 100% !important;
                    }
                }
            }
        }

        button.primary.larger {
            margin: 40px auto -45px auto;
            float: none;
            display: block;
        }
    }
}

@media (min-width: 345px) {
    .budget-planner {
        section {
            .subsection {
                .table-and-summary {
                    span.summary-amount {
                        top: 75px !important;
                    }
                }
            }
        }
    }
}
