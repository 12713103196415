@import '../../styles/vars';

.cashflow-goals {
    .MuiToggleButtonGroup-root {
        position: absolute;
        top: 51px;
        left: 420px;
        width: 360px;
        height: 36px;
        background: transparent;

        .MuiButtonBase-root {
            background-color: $white;
            color: #B3B3B3;
            width: 120px;
            height: 36px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        }

        .MuiToggleButtonGroup-grouped {
            border-radius: 0 !important;

            &:first-child {
                border-radius: 20px 0px 0px 20px !important;
            }

            &:last-child {
                border-radius: 0 20px 20px 0 !important;
            }
        }

        .MuiToggleButton-root.Mui-selected {
            background-color: $brand-primary;
            color: $white;
        }
    }

    h1 {
        display: flex;
        align-items: center;

        .icon-button {
            top: 0;
            left: 5px;
        }
    }

    h2.sub.light {
        display: block;
        margin: 45px 0 20px 30px;
    }

    .section-wrapper {
        width: 100%;
        min-height: 165px;
        background-color: $white;
        border-radius: 10px;
        margin: 0 0 50px 0;
        position: relative;
        border: 2px solid #F4F6F8;

        &.review {
            height: 680px;
        }

        .cover {
            background: rgba(38, 39, 63, 0.3);
            width: calc(100% + 4px);
            height: calc(100% - 161px);
            position: absolute;
            top: 163px;
            z-index: 1;
            border-radius: 0 0 10px 10px;
            margin: 0 0 0 -2px;

            &.secondary {
                top: 0;
                height: calc(100% + 4px);
                border-radius: 10px;
                margin: -2px 0 0 -2px;
            }
        }

        section {
            padding: 20px 40px 25px 30px;

            &:nth-child(even) {
                background-color: #F4F6F8;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }

            h1 {
                .icon-button {
                    top: 0;
                    left: 7px;
                }
            }

            h3 {
                margin: 0 0 25px 0;
            }

            .start-end-date {
                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    color: $brand-primary;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 0 10px 0 0;
                }

                select {
                    width: 130px !important;
                    border: 1px solid $brand-primary;
                    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
                    border-radius: 5px;
                    width: 115px;
                    padding: 8px 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: $brand-primary;
                }

                img {
                    padding: 0 20px 0 5px;
                }

                a.edit {
                    font-size: 18px;
                    line-height: 22px;
                    color: $brand-secondary;
                    text-decoration: none;
                    margin: 0 0 0 auto;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .table-and-summary {
                .summary {
                    text-align: right;

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $brand-primary;
                    }

                    .summary-extra {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding-top: 15px;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            &.balance-check {
                padding: 0;

                h1 {
                    padding: 20px 0 0 30px;
                }

                h3 {
                    padding: 0 40px 0 30px;
                }

                .summary {
                    .summary-row {
                        display: flex;
                        align-items: center;
                        padding: 12px 150px;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

                        &:first-child {
                            background-color: #F4F6F8;
                            box-shadow: none;
                        }

                        img {
                            width: 40px;
                            height: 40px;
                            margin: 0 15px 0 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: $brand-primary;

                            .income {
                                color: $dataviz-1;
                            }

                            .savings {
                                color: $dataviz-3;
                            }

                            .spending {
                                color: $dataviz-7;
                            }
                        }
                    }
                }

                .pie-chart {
                    padding: 0 40px 0 30px;
                    display: flex;
                    position: relative;

                    .legend {
                        width: 25%;
                        margin: 60px 0;

                        .legend-row {
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            margin: 0 0 38px 0;

                            label {
                                font-weight: 700;
                                font-size: 21px;
                                line-height: 25px;
                                margin: 0 12px 0 0;

                                &.income {
                                    color: $dataviz-1;
                                }

                                &.savings {
                                    color: $dataviz-3;
                                }

                                &.spending {
                                    color: $dataviz-7;
                                }
                            }
                        }
                    }

                    .balance-line {
                        width: 93%;
                        height: 20px;
                        text-align: right;
                        position: absolute;
                        left: 30px;
                        display: flex;
                        align-items: center;

                        .line {
                            width: 100%;
                            border-bottom: 2px dashed #B3B3B3;
                        }

                        label {
                            width: 12%;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: #B3B3B3;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }

            &.accomplishments {
                width: 100%;
                height: 675px;
                padding: 0;

                .header {
                    height: 165px;
                    display: flex;

                    .desc {
                        background-color: $white;
                        padding: 20px 0 0 30px;
                    }

                    .empty-space {
                        width: 730px;
                        background-color: #F4F6F8;
                    }
                }

                .category-section {
                    height: 170px;
                    display: flex;
                    background-color: $white;

                    .category-metadata {
                        width: 355px;

                        h1 {
                            height: 30px;
                            background-color: #F4F6F8;
                            font-weight: 700;
                            font-size: 21px;
                            line-height: 25px;
                            margin: 0;
                            padding: 10px 35px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;

                            img {
                                width: 35px;
                                height: 35px;
                                margin-right: 10px;
                            }
                        }

                        .summary {
                            display: flex;
                            align-items: flex-start;
                            margin: 10px 20px;

                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }

                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: $brand-primary;
                            }
                        }
                    }

                    .summary {
                        display: none;
                    }
                }
            }

            &.plan-pacing {
                width: 100%;
                height: 100%;
                padding: 0;
                position: relative;
                border: 2px solid $white;

                .background {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    z-index: 10;
                    position: absolute;

                    .header {
                        width: 30%;
                        height: calc(100% - 20px);
                        padding: 20px 0 0 30px;
                        background-color: $white;

                        h2 {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 85%;
                            margin-left: 5px;

                            .pacing-legend {
                                display: inline-block;
                                width: 30px;
                                border-top: 2px dashed $brand-secondary;
                                height: 2px;
                                padding-left: 10px;
                            }
                        }

                        a.filter-by {
                            display: none;
                        }
                    }

                    .months {
                        width: 68%;
                        height: 100%;
                        display: flex;
                        background-color: #F4F6F8;

                        .month {
                            border-right: 2px solid $white;
                            padding: 60px 12px 0 12px;
                            text-align: center;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #9D9D9D;

                            &:first-child {
                                border-left: 2px solid $white;
                            }

                            &:last-child {
                                border: none;
                                border-radius: 0 0 10px 0;
                            }

                            &.active {
                                color: $brand-secondary;
                                font-weight: 700;
                                background-color: #E6E9ED;
                            }
                        }
                    }
                }

                .foreground {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    z-index: 100;
                    position: absolute;
                    top: 170px;

                    .pacing-section {
                        width: 100%;
                        height: 170px;
                        display: flex;

                        .pacing-metadata {
                            background-color: #F4F6F8;
                            width: calc(30% - 10px);
                            padding: 5px 15px 25px 25px;

                            &.even {
                                background-color: $white;
                            }

                            .category-info {
                                display: flex;
                                align-items: center;
                                margin: 0 0 25px 0;

                                img {
                                    width: 35px;
                                    height: 35px;
                                    margin-right: 10px;
                                }

                                label {
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $brand-primary;
                                    margin-right: 25px;
                                    width: 104px;
                                }

                                select {
                                    width: 135px;
                                    height: 36px;
                                    background-color: $white;
                                    border: 1px solid $brand-primary;
                                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                                    border-radius: 5px;
                                    padding: 8px 10px;
                                }
                            }

                            .summary {
                                display: flex;
                                align-items: flex-start;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }

                                span {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }

                .today-container {
                    width: 66.8%;
                    height: 585px;
                    margin-left: 33%;
                    z-index: 1000;
                    position: absolute;
                    top: 95px;

                    .marker {
                        width: 2px;
                        border-left: 2px dashed $brand-secondary;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }

    button.save-plan {
        float: right;
    }

    .breakdowns {
        margin: 15px 0 0 0;
        display: flex;
        justify-content: space-between;

        .breakdown-section {
            width: 30%;
            min-height: 325px;
            background-color: $white;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
            border-radius: 10px;

            h2 {
                margin: 20px 0 0 20px;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: $brand-primary;
                display: flex;
                align-items: center;

                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 15px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $brand-primary;
                margin: 10px 0 10px 20px;
            }

            .pie-chart {
                width: 150px;
                margin: 20px auto;
            }

            .pie-tooltip {
                background-color: #FFF;
                border: 2px solid #378088;
                border-radius: 25px;
                padding: 10px;
                box-shadow: 0px 10px 20px #000000;
            }

            .breakdown-legend {
                width: 100%;
                height: 53px;
                background-color: $brand-primary;
                border-radius: 0px 0px 10px 10px;

                .legend-contents {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    span.icon {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    label, span.amounts {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-right: 5px;
                    }

                    span.amounts {
                        color: $white;
                    }
                }
            }
        }
    }
}

span.summary-amount {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: $white;
    padding: 8px 15px;
    margin: 0 0 0 10px;

    &.income {
        background-color: $dataviz-1;
    }

    &.savings {
        background-color: $dataviz-3;
    }

    &.spending {
        background-color: $dataviz-7;
    }
}

.pacing-bar {
    width: 730px;
    background-color: #F4F6F8;

    &.review {
        width: 68%;
        background: transparent;

        .bar {
            margin: 0 0 120px 0;
            left: 2px;

            .pace.full {
                padding-right: 0px !important;
            }

            label.bar-goal {
                color: $brand-primary;
            }
        }
    }

    .bar {
        width: auto;
        height: 50px;
        background-color: $white;
        position: relative;
        padding: 0 20px 0 0;
        text-align: right;

        .pace {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-radius: 0px 30px 30px 0px;
            position: absolute;
            z-index: 10;

            label.total {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                position: relative;
                right: -90px;
                padding-left: 10px;

                &.off-track {
                    color: $status-red;
                }

                &.on-track {
                    color: #7CD4B7;
                }

                &.inside {
                    color: $white;
                    padding: 0 20px 0 0;
                    right: 0px;
                }

                &.zero {
                    right: 5px;
                }
            }

            &.off-track {
                background-color: $status-red;
            }

            &.on-track {
                background-color: #7CD4B7;
            }

            &.full {
                border-radius: 0px;
                padding-right: 2px;
            }

            &.zero {
                justify-content: flex-start;
            }
        }

        label.bar-goal {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #B3B3B3;
            position: relative;
            top: 14px;
        }
    }
}

.review-filter {
    width: 100%;
    height: 100%;
    margin: 50px 0 30px 0;

    .review-filter-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px 15px 15px;

        h1 {
            margin: 0 0 0 15px !important;
        }
    }

    .filter-category {
        background: #F4F6F8;
        border-radius: 10px;
        padding: 15px 20px;
        margin: 0 15px 2px 15px;

        h2 {
            color: #B3B3B3;
            margin: 0 !important;
            padding: 0 !important;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
        }

        .filter-label {
            padding: 0;

            &:last-child {
                border-bottom: none;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 10px 0 0 0;

                li {
                    display: flex;
                    align-items: center;
                    margin: 0 0 15px 0;

                    input {
                        padding: 0 4px 0 0;
                        color: $brand-secondary;
                    }

                    label {
                        font-weight: 700;
                        color: $brand-primary;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .cashflow-goals {
        .MuiToggleButtonGroup-root {
            position: static;
            display: block;
            width: 305px;
            height: 50px;
            margin: 15px auto 0 auto;

            .MuiButtonBase-root {
                width: 101px;
                height: 50px;
            }

            .MuiToggleButtonGroup-grouped {
                border-radius: 0 !important;

                &:first-child {
                    border-radius: 10px 0px 0px 10px !important;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0 !important;
                }
            }
        }

        h1 {
            margin: 30px 0 15px 20px;
        }

        h2.sub.light {
            margin: 15px 20px 33px 40px;
            font-size: 14px;
            line-height: 17px;
        }

        .section-wrapper {
            border: none;
            border-radius: 0;
            background-color: transparent;
            margin: 0;
            padding: 0;

            .cover {
                top: 316px;
                height: calc(100% - 316px);
                border-radius: 0;

                &.secondary {
                    border-radius: 0;
                    top: 2px;
                    height: 100%;
                }
            }

            section {
                margin: 0 0 25px 0;
                padding: 35px 20px !important;
                background-color: $white !important;

                &.update {
                    margin: 0;
                }

                &:last-child {
                    border-radius: 0;
                }

                h1 {
                    margin: 0 0 10px 0 !important;
                    padding: 0 !important;
                    font-size: 18px;
                    line-height: 22px;
                }

                h3 {
                    margin: 0 0 15px 0 !important;
                    padding: 0 !important;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                }

                .start-end-date {
                    flex-direction: column;
                    align-items: flex-start;

                    &.update {
                        flex-direction: row;
                        align-items: center;
                    }

                    .month-wrapper {
                        width: 85%;
                        margin: 0 auto 35px auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &:nth-child(2) {
                            margin: 0 auto;
                        }

                        label {
                            width: 45px;
                            text-align: right;
                        }

                        select {
                            margin-left: 10px;
                        }
                    }

                    .edit {
                        position: absolute;
                        top: 35px;
                        left: 200px;
                    }
                }

                .table-and-summary {
                    position: relative;

                    .summary {
                        text-align: left;
                        margin: 0 0 0 20px;

                        p {
                            width: 85%;
                            font-size: 12px;
                            line-height: 14px;
                            margin: 15px 0 0 0;

                            span {
                                display: table;
                                margin: 15px 0 0 0;
                                padding: 5px 18px;
                            }
                        }

                        .summary-extra {
                            padding-top: 30px;
                            margin-left: -20px;

                            img {
                                width: 18px;
                                height: 18px;
                            }

                            span {
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }
                    }
                }

                &.balance-check {
                    .summary {
                        display: none;
                    }

                    .pie-chart {
                        padding: 0;
                        display: block;

                        .legend {
                            width: 100%;
                            margin: 0;

                            .legend-row {
                                margin: 0 0 30px 0;
                                justify-content: center;

                                label {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    text-align: right;
                                    display: block;
                                }

                                span {
                                    font-size: 18px;
                                    line-height: 22px;
                                    padding: 5px 15px;
                                }
                            }
                        }

                        .balance-line {
                            left: 0;

                            .line {
                                border-bottom-width: 1px;
                            }

                            label {
                                font-size: 10px;
                                line-height: 12px;
                            }
                        }
                    }
                }

                &.accomplishments {
                    height: 555px;

                    .header {
                        height: auto;

                        .desc {
                            padding: 0;

                            h3 {
                                width: 90%;
                                font-size: 14px;
                                line-height: 18px;
                                margin: 0 0 40px 0 !important;
                            }
                        }

                        .empty-space {
                            display: none;
                        }
                    }

                    .category-section {
                        flex-direction: column;
                        height: 160px;

                        .category-metadata {
                            width: auto;

                            h1 {
                                font-size: 14px;
                                line-height: 17px;
                                height: auto;
                                justify-content: flex-start;
                                background-color: $white;
                                margin: 0 0 5px 0;

                                img {
                                    display: none;
                                }
                            }

                            .summary {
                                display: none;
                            }
                        }

                        .pacing-bar {
                            width: 90%;

                            .bar {
                                background-color: #E6E6E6;

                                label.bar-goal {
                                    color: $brand-primary;
                                    right: -10px;
                                }
                            }
                        }

                        .summary {
                            display: flex;
                            align-items: baseline;
                            width: 85%;
                            margin: 10px 0 35px 0;

                            img {
                                width: 18px;
                                height: 18px;
                                position: relative;
                                top: 5px;
                                padding-right: 5px;
                            }

                            span {
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }

            &.review {
                height: 600px;

                section.plan-pacing {
                    padding: 0 !important;
                    margin: 0;

                    .background {
                        flex-direction: column;
                        height: calc(100% - 15px);

                        .header {
                            width: 93%;
                            height: 150px;
                            padding: 30px 0 30px 20px !important;

                            h2 {
                                margin: 0 0 0 20px;
                                display: block;

                                .pacing-legend {
                                    width: 20px;
                                    height: 5px;
                                    margin-left: 10px;
                                }
                            }

                            a.filter-by {
                                display: block;
                                margin: 10px 0 0 20px;
                                color: $brand-secondary;
                                text-decoration: none;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .months {
                            width: 100%;

                            .month {
                                padding: 10px 0 0 0;
                                font-size: 14px;
                                line-height: 17px;

                                &:first-child {
                                    border-left: none;
                                }
                            }
                        }
                    }

                    .foreground {
                        top: 235px;

                        .pacing-section {
                            flex-direction: column;
                            height: 120px;

                            .pacing-metadata {
                                width: 100%;
                                height: auto;
                                padding: 0;
                                background-color: transparent;

                                .category-info {
                                    margin: 0;

                                    img {
                                        display: none;
                                    }

                                    label {
                                        width: auto;
                                        margin: 0 0 10px 20px;
                                    }

                                    select {
                                        display: none;
                                    }
                                }

                                .summary {
                                    display: none;
                                }
                            }

                            .pacing-bar {
                                width: 100%;

                                .bar {
                                    left: 0;
                                    margin: 0;

                                    .bar-goal {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .today-container {
                        width: 100%;
                        height: 390px;
                        margin-left: 0;
                        top: 195px;
                    }
                }
            }
        }

        .plan-breakdown {
            margin: 40px 0 30px 20px;

            h1 {
                margin: 0 0 30px 0;
            }

            .breakdowns {
                margin: 0;
                flex-direction: column;

                .breakdown-section {
                    width: 93%;
                    margin: 0 0 30px 0;

                    span.icon {
                        margin-right: 5px !important;
                    }
                }
            }
        }

        .save-plan {
            margin: 40px auto;
            float: none !important;
            display: flex;
            font-weight: 500;
            font-size: 16px !important;
            line-height: 23px !important;
        }
    }
}
