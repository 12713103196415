@import '../../styles/vars';

.financial-priorities {
    .fp-header {
        position: relative;
        top: 0;
        width: 1073px;
        background: $brand-tertiary;

        h1 {
            span {
                cursor: pointer;

                &:hover {
                    color: $brand-secondary;
                    text-decoration: underline;
                }
            }

            .arrows {
                display: inline-flex;
                margin-left: 20px;

                .arrow {
                    &.back {
                        margin-right: 10px;
                    }
                }
            }
        }

        h2 {
            &.description {
                font-weight: normal;
                font-size: 14px;
                margin: 25px 0 0 0;
            }

            a {
                color: $brand-primary;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &.selected {
                    color: $brand-secondary;
                }
            }
        }

        .help {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -5px;
            right: 0px;

            span {
                display: inline-block;
                margin: 0 10px 0 0;
                color: $brand-secondary;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }
    }

    .slideshow {
        width: 100%;
        height: 400px;
        display: flex;
        background-color: $white;
        border-radius: 10px;
        margin: 60px 0 20px 0;

        .slide-image {
            width: 50%;
            height: 100%;
            border-radius: 10px 0 0 10px;
        }

        .slideshow-content {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .close-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 20px;
                right: 10px;
                cursor: pointer;
            }

            h1 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $black;
                width: 68%;
                margin: 0 0 20px 0;

                em {
                    font-style: normal;
                    color: $brand-secondary;
                }
            }

            p {
                width: 68%;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $black;
                margin: 0;
            }

            .arrows {
                margin: 50px 0 0 85px;
            }
        }
    }

    .arrows {
        display: flex;
        align-self: flex-start;

        .arrow {
            font-size: 29px;
            color: $brand-primary;
            cursor: pointer;
            transform: scaleY(1.3);

            &.back {
                margin-right: 20px;
            }

            &.disabled {
                color: $lightgrey;
                cursor: default;
            }
        }
    }

    .slideshow-dots {
        width: 100%;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
            width: 10px;
            height: 10px;
            background-color: $white;
            border-radius: 50%;
            margin-right: 12px;
            cursor: pointer;

            &.selected {
                background-color: $brand-primary;
            }
        }
    }

    section.section-next-steps {
        width: 62%;
        height: 240px;
        margin-top: 80px;
    }

    section.quote-and-slideshow {
        width: 32%;
        height: 300px;
        margin: 80px 0 0 0;

        .quote-or-collapsed {
            width: 210px;
            height: 150px;
            top: 5px;
            left: 50px;
            padding: 45px 20px 55px 20px;

            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
            }

            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $white;
                margin: 0;
            }
        }

        .quote-background {
            width: 250px;
            height: 250px;
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url('../../images/backgrounds/quote.jpg');
            background-size: cover;
        }
    }

    .quote-or-collapsed.mobile, .quote-background.mobile, .quote-and-slideshow.mobile {
        display: none;
    }

    .tier-header {
        width: 100%;
        height: 400px;
        display: flex;
        background-color: $white;
        border-radius: 10px;
        margin: 40px 0 70px 0;
        position: relative;

        .header-content {
            width: 100%;
            height: 100%;
            padding: 55px 55px 55px 80px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            img.tier-icon {
                height: 25px;
                margin: 0 0 10px 0;
            }

            h1 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $brand-primary;
                margin: 0 0 10px 0;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $black;
                margin: 0 0 55px 0;
            }
        }

        .tier-image {
            width: 50%;
            height: 100%;
            border-radius: 0 10px 10px 0;
        }

        .tier-nav-mobile {
            display: none;
        }

        .tier-nav {
            width: 55px;
            height: 55px;
            position: absolute;
            top: 42%;
            cursor: pointer;

            &.previous {
                right: 43%;
                transform: rotateY(180deg);
            }

            &.next {
                right: 20px;
            }
        }
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .category {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            background-color: $white;
            border-radius: 10px;
            padding: 20px 10px 15px 20px;
            margin: 0 0 20px 0;

            h2 {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                margin: 0 0 10px 0;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: $brand-primary;
                margin: 0 0 25px 0;
            }

            .progress {
                .progress-details {
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            svg.arrow-icon {
                position: absolute;
                bottom: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    .category-wrapper {
        margin: 40px 0 100px 0;
        display: flex;

        .category-detail {
            width: 65%;
            height: auto;
            margin: 0;
            padding: 0;
            background-color: $white;
            border-radius: 10px;

            .header {
                padding: 20px 30px 50px 25px;
                display: flex;
                align-items: flex-start;

                .category-icon {
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                }

                .category-nav-mobile {
                    display: none;
                }
                .progress {
                    margin: 0 0 0 auto;
                    width: 320px;

                    .progress-bar {
                        margin: 0 0 10px 0;
                    }

                    .progress-details {
                        font-size: 12px;
                        line-height: 14px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            p.no-topics {
                margin-left: 25px;
            }

            .topic {
                padding: 20px 25px;
                background-color: $white;

                &:nth-child(even) {
                    background-color: #F4F6F8;
                }

                &.other-topics {
                    &.collapsed {
                        display: none;
                    }

                    h2 {
                        padding: 0 !important;
                    }
                }

                .topic-header {
                    display: flex;
                    align-items: center;

                    h2 {
                        padding: 0 0 0 35px;
                        margin: 0;
                    }

                    svg.MuiSvgIcon-root {
                        color: $brand-secondary;
                        cursor: pointer;
                    }

                    .tasks-progress {
                        margin: 0 20px 0 auto;
                    }

                    a.edit {
                        color: $brand-secondary;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                .description {
                    margin: 15px 0 10px 40px;

                    p {
                        color: #B3B3B3;
                        margin: 0;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                .tasks {
                    margin: 0;

                    &.collapsed {
                        display: none;
                    }

                    .task-line {
                        cursor: auto;
                        padding: 8px 10px;

                        &.completed {
                            background-color: rgba(64, 183, 176, 0.6);
                        }

                        .MuiButtonBase-root {
                            color: $brand-secondary;
                            padding: 0 15px 0 0;
                        }

                        .arrow-icon, .remove-icon, .plus-icon {
                            cursor: pointer;
                        }

                        .remove-icon {
                            border: none;
                            margin: -5px 0;
                            padding: 0;
                            width: 36px;
                            height: 36px;
                            position: relative;
                            right: -6px;
                        }

                        .plus-icon {
                            width: 25px;
                            height: 25px;
                            right: 0;
                        }
                    }
                }
            }
        }

        .category-description {
            width: 32%;
            height: 100%;
            position: relative;

            .description {
                width: 210px;
                height: 370px;
                position: absolute;
                top: 125px;
                left: 60px;
                padding: 45px 20px 35px 20px;
                background-color: rgba(38, 39, 63, 0.9);
                border-radius: 10px;
                z-index: 10;

                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0;
                    color: $white;
                }
            }

            .background {
                width: 250px;
                height: 450px;
                border-radius: 10px;
                position: absolute;
                top: 165px;
                left: 100px;
                background: url('../../images/backgrounds/category.jpg');
                background-size: cover;
            }
        }
    }
}

@media (max-width: 1439px) {
    .financial-priorities {
        .fp-header {
            width: auto;

            h1 {
                margin: 25px 20px 0 20px;

                &.cat-header {
                    color: #B3B3B3;
                }
            }

            h2.description {
                margin: 20px;
            }

            .help {
                display: none;
            }
        }

        .slideshow {
            margin: 0 20px;
            width: calc(100% - 40px);
            flex-direction: column-reverse;

            .slide-image {
                width: 100%;
                height: 120px;
                border-radius: 0 0 10px 10px;
            }

            .slideshow-content {
                width: 100%;
                height: 280px;
                justify-content: normal;
                align-items: inherit;

                img.close-icon {
                    width: 25px;
                    height: 25px;
                    top: 15px;
                    right: 15px;
                }

                h1 {
                    font-size: 21px;
                    line-height: 24px;
                    width: auto;
                    margin: 50px 20px 10px 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    width: auto;
                    margin: 0 20px 20px 20px;
                }
            }
        }

        .arrows, .tier-nav-mobile, .category-nav-mobile {
            margin: 0 auto !important;
            width: 110px;
            height: 40px;
            position: absolute;
            top: 460px;
            left: 0;
            right: 0;

            .arrow {
                width: 40px;
                height: 40px;
                background-color: $brand-primary;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                color: $brand-tertiary;
                transform: none;
                display: flex;
                justify-content: center;

                &.back {
                    margin-right: 30px;
                }

                &.disabled {
                    background-color: $white;
                }
            }
        }

        .slideshow-dots {
            margin: 20px 0 120px 0;
        }

        section.section-next-steps {
            width: 100%;
            height: auto;
            border-radius: 0;
            margin: 0 0 40px 0 !important;
            padding: 0;

            h1 {
                margin: 30px 0 25px 20px;
            }

            .next-steps {
                margin: 0 0 35px 0;

                .next-step {
                    margin: 0 10px 15px 10px;

                    .faux-checkbox {
                        display: none;
                    }

                    .task {
                        font-size: 14px;
                        line-height: 17px;
                    }

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        .quote-and-slideshow {
            display: none;
        }

        .quote-collapsed-mobile {
            display: block;
            padding: 35px 20px;
            clear: both;
            background-color: $white;
            position: relative;
            margin: 0 0 25px 0;

            .quote-or-collapsed.mobile {
                display: block;
                min-height: 280px;
                background-color: rgba(38, 39, 63, 1);
                border-radius: 10px;
                z-index: 100;
                position: relative;

                h2 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $white;
                    padding: 42px 35px;
                    margin: 0;
                    position: relative;
                    z-index: 100;
                }

                svg {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    z-index: 100;
                }
            }

            .quote-background.mobile {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                background: url('../../images/backgrounds/quote-mobile.jpg');
                background-size: cover;
                opacity: 0.4
            }
        }

        .quote-and-slideshow.mobile {
            display: block;
            width: 100%;
            height: 280px;
            margin: 0;
            border-radius: 0;
            position: relative;

            .quote-or-collapsed {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                top: 0;
                left: 0;
                padding: 0;
                border-radius: 0;

                p {
                    padding: 35px 30px;
                }
            }

            .quote-background {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 0;
                background: $brand-primary;
                background-image: linear-gradient(178deg, #26273F -100%, rgba(38, 40, 50, 0) 100%), url('../../images/backgrounds/assets-liabilities.jpg');
                background-size: cover;
            }
        }

        section.action-plan {
            h1 {
                margin-left: 20px;
            }

            .tiers {
                margin: 30px 10px 25px 10px;

                .tier {
                    width: 45%;
                    min-width: 145px;
                    max-width: 200px;
                    height: 145px;
                    margin: 0 0 15px 0;

                    .metadata {
                        height: 20px;
                        margin: 12px 0;

                        img {
                            height: 15px;
                            margin-right: 5px;

                            &:nth-of-type(1) {
                                width: 20px;
                            }
                        }

                        h2 {
                            font-size: 14px;
                            line-height: 17px;
                            margin: 0;
                            position: relative;
                            top: -1px;
                        }
                    }

                    .pie {
                        height: 70px;

                        label {
                            font-size: 21px;
                            top: 28%;
                        }

                        svg.arrow-icon {
                            width: 18px;
                            height: 18px;
                            bottom: -22px;
                            right: 10px;
                        }
                    }
                }
            }
        }

        h2.sub.cat-nav {
            display: none;
        }

        .tier-header {
            margin: 25px auto 100px auto;
            flex-direction: column;
            width: calc(100% - 40px);
            height: auto;

            .header-content {
                width: 95%;
                padding: 25px 0 0 30px;

                h1 {
                    font-size: 21px;
                    line-height: 25px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0 0 30px 0;
                    width: 75%;
                }
            }

            img.tier-image {
                margin: 30px 0 0 0;
                border-radius: 0 0 10px 10px;
                width: 100%;
            }

            img.tier-nav {
                display: none;
            }

            .tier-nav-mobile {
                top: 15px;
                height: 0;
                display: flex;
                position: relative;

                .arrow {
                    font-size: 29px;
                    color: $brand-tertiary;
                    cursor: pointer;
                }
            }
        }

        .categories {
            width: calc(100% - 40px);
            flex-direction: column;
            margin: 0 auto 150px auto;

            .category {
                width: 88%;
            }
        }

        .category-wrapper {
            margin: 20px 0 0 0;
            width: 100%;
            flex-direction: column;

            .category-detail {
                width: 100%;
                border-radius: 0;
                background-color: transparent;

                .header {
                    padding: 30px 20px;
                    flex-wrap: wrap;
                    background-color: $white;
                    margin-bottom: 25px;

                    h1 {
                        margin: 0;
                        width: 67%;
                        margin: 0 0 50px 0;
                    }

                    .category-nav-mobile {
                        display: block;
                        position: relative;
                        top: -5px;
                        width: 40px;

                        .arrow {
                            font-size: 29px;
                        }
                    }

                    .progress {
                        width: 97%;
                        margin: 0 0 0 5px;

                        .progress-details {
                            font-size: 10px;
                            line-height: 12px;
                        }
                    }
                }

                .topic {
                    padding: 20px;

                    .topic-header {
                        h2 {
                            padding: 0 0 0 10px;
                        }
                    }

                    .tasks {
                        .task-line {
                            .MuiButtonBase-root, .faux-checkbox {
                                display: none;
                            }

                            .task {
                                font-size: 14px;
                                line-height: 17px;
                                letter-spacing: -0.02em;
                            }

                            svg {
                                width: 18px;
                                height: 18px;
                            }

                            &.completed {
                                flex-direction: row-reverse;

                                .MuiButtonBase-root {
                                    display: block;
                                    margin: 0;
                                    padding: 0;
                                }

                                svg.arrow-icon {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .category-description {
                width: 100%;
                min-height: 370px;

                .description, .background {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 0;
                    padding: 0;
                    background-size: auto;

                    p {
                        padding: 35px 20px 35px 30px;
                    }
                }
            }
        }
    }
}
