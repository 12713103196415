@import '../../styles/vars';

.nav {
    section {
        .nav-header {
            img.logo {
                width: 70px;
                height: 70px;
                margin: 23px 0 0 22px;
            }

            h2 {
                display: none;
            }

            .menu-icon {
                display: none;
            }
        }

        .links-wrapper {
            ul.nav-links {
                list-style-type: none;
                padding: 0;

                li.nav-item {
                    height: 50px;
                    padding: 0 0 0 23px;
                    margin: 0;
                    color: #F4F6F8;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;

                    &.selected, &:hover {
                        background-color: $nav-hover;

                        a {
                            color: $brand-secondary
                        }
                    }

                    &.selected {
                        a {
                            font-weight: 800;
                            color: $brand-secondary
                        }
                    }

                    &.section {
                        cursor: auto;

                        &:hover {
                            background: $brand-primary;
                            color: #F4F6F8;
                        }
                    }

                    a {
                        color: #F4F6F8;
                        text-decoration: none;
                        padding: 0;
                        display: inline-block;
                        position: relative;

                        .badge {
                            color: $white !important;
                            position: absolute;
                            right: -35px;
                            top: -3px;

                            &.beta {
                                width: 40px;
                                height: 20px;
                                background-color: $brand-secondary;
                                font-weight: 400;
                                font-size: 12px;
                                border-radius: 5px;
                                padding: 0;
                                top: -1px;
                                right: -55px;
                            }
                        }
                    }

                    &.sub {
                        height: 60px;
                        padding: 0 0 0 35px;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        line-height: 19px;

                        &.extra-padding {
                            padding: 10px 0 10px 35px;
                        }

                        a {
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 17px;

                            .status {
                                margin-top: 10px;
                                display: flex;
                                align-items: center;

                                .bar {
                                    width: 129px;
                                    height: 8px;
                                    background-color: #F4F6F8;
                                    border-radius: 10px;
                                    position: relative;

                                    .progress {
                                        height: 8px;
                                        background-color: $brand-secondary;
                                        border-radius: 10px;
                                        position: absolute;
                                        z-index: 10;
                                    }
                                }

                                label {
                                    font-weight: 800;
                                    font-size: 12px;
                                    line-height: 11px;
                                    color: $brand-secondary;
                                    position: relative;
                                    top: 0;
                                    left: 5px;
                                    cursor: pointer;
                                }

                                &.trial {
                                    margin: 11px 0 10px;

                                    .bar {
                                        background-color: $status-red;

                                        .progress {
                                            background-color: $brand-secondary;
                                        }
                                    }
                                }
                            }

                            span {
                                color: $brand-secondary;
                            }
                        }
                    }

                    &.secondary {
                        height: 60px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        padding: 0 0 0 50px;

                        a {
                            padding: 0;
                        }
                    }
                }
            }

            .divider {
                height: 0px;
                margin: 44px 13px;
                border-bottom: 1px solid #E6ECEE;
            }
        }
    }
}

@media (max-width: 1439px) {
    .nav {
        position: absolute;
        z-index: 1000;
        height: 70px;

        &.mobile-menu-open {
            background-color: $white !important;
            height: 650px !important;

            &.on-fp {
                height: 900px !important;
            }
        }

        section {
            .nav-header {
                background-color: $brand-primary;
                display: flex;
                align-items: center;
                padding: 11px 22px 13px 28px;
                position: fixed;
                width: calc(100vw - 50px);

                img.logo {
                    width: 42px;
                    height: 42px;
                    margin: 0 10px 0 0;
                }

                h2 {
                    display: inline;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: $brand-tertiary;
                    margin: 0;
                    flex: 2;
                }

                img.menu-icon {
                    display: block;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .links-wrapper {
                display: none;

                &.mobile-menu-open {
                    display: block;
                    background-color: $white;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                    scrollbar-width: none;
                    overflow-y: scroll;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    width: calc(100vw - 0px);
                    margin-top: 70px;
                }

                ul.nav-links {
                    margin: 0;

                    &:first-child {
                        margin: 27px 0 0 0;
                    }

                    li.nav-item {
                        width: auto !important;
                        height: 50px !important;
                        justify-content: center;
                        padding: 5px 20px !important;
                        margin: 0 !important;

                        &.selected, &:hover {
                            background: $brand-secondary !important;

                            a {
                                color: $white !important;
                            }

                            &.sub, &.secondary {
                                a {
                                    color: $white !important;
                                }
                            }
                        }

                        &.section {
                            display: none;
                        }

                        &.sub, &.secondary {
                            display: block !important;
                            text-align: center;
                            height: 50px !important;
                            text-align: center;
                            padding: 5px 20px !important;
                            margin: 0 !important;

                            a {
                                font-weight: 700 !important;
                                font-size: 18px !important;
                                line-height: 22px !important;
                                padding: 14px 43px !important;
                                color: $brand-primary !important;

                                .status {
                                    display: none !important;
                                }
                            }
                        }

                        a {
                            font-weight: 700 !important;
                            font-size: 18px !important;
                            line-height: 22px !important;
                            padding: 14px 43px !important;
                            color: $brand-primary;

                            .badge.beta {
                                top: 15px;
                                right: -10px;
                                line-height: 20px;
                            }
                        }
                    }
                }

                .divider {
                    margin: 22px 15px;
                }
            }
        }
    }
}
