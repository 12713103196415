@import '../../styles/vars';

.subscription-settings {
    h1 {
        margin: 0 0 40px 0;
    }

    h2 {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 25px 0;
        clear: both;

        &.ss-alt {
            font-size: 18px;
            font-weight: 700;
            margin: 50px 0 25px 0;
        }
    }

    a.back-link {
        font-size: 18px;
        font-weight: 400;
        padding: 0 0 40px 0;
        display: inline-block;
    }

    .plans {
        display: flex;

        .plan {
            width: 310px;
            height: 190px;
            background-color: $white;
            border-radius: 10px;
            padding: 10px 15px;
            margin: 0 40px 25px 0;
            border: 4px solid $white;
            cursor: pointer;

            h3 {
                color: $brand-secondary;
                font-size: 22px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 0 25px 0;
            }

            label {
                font-size: 22px;
                font-weight: 700;
                color: $brand-primary;
                display: block;
                margin: 0 0 15px 0;
                cursor: pointer;
            }

            &.selected {
                border: 4px solid $brand-secondary;
            }
        }
    }

    input {
        width: 250px;
        padding: 8px 10px;
        background-color: $white;
        border: 1px solid $brand-primary;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $brand-primary;

        ::placeholder {
            color: #B3B3B3;
        }

        &.disabled {
            background-color: transparent;
            border: none;
            color: $brand-primary;
            font-size: 16px;
            font-weight: 400;
            box-shadow: none;
            padding-left: 1px;
        }
    }

    form {
        fieldset {
            border: none;
            padding: 0;
            margin: 0 0 15px 0;
            clear: both;

            label {
                color: $brand-primary;
                font-size: 14px;
                font-weight: 700;
                display: block;
                margin: 0 0 5px 0;
            }

            &.half {
                width: 100px;
                float: left;
                margin: 0 28px 15px 0;
                clear: none;

                input {
                    width: 100px;
                }
            }

            &.last {
                margin-bottom: 35px;
            }
        }

        button.secondary {
            color: $brand-primary !important;
            background: $white !important;
            border-color: $brand-primary !important;
            margin-right: 17px;
        }

        &.cancel-account, &.delete-account {
            padding-bottom: 0;
            margin: 0;

            h2 {
                font-weight: 700;
                font-size: 18px;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                display: block;
                margin: 0 0 25px 0;
                max-width: 1045px;
            }

            input[type=text] {
                border: 1px solid #B3B3B3;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                width: 205px;
            }

            .cancel {
                background: #F4F6F8;
                border: 2px solid #EB535C;
                box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
                border-radius: 20px !important;
                color: #EB535C;
                padding: 6px 23px;
                font-family: "Jost" !important;
                font-style: normal;
                font-weight: 500;
                font-size: 16px !important;
                line-height: 23px !important;
                position: relative;
                top: -54px;
                left: 252px;
            }
        }

        a.change-payment {
            display: block;
            width: 215px;
            margin: 25px 0 0 0;
            color: $brand-secondary;
            text-decoration: none;
            font-size: 18px;
            font-weight: 700;
            clear: both;

            &:hover {
                text-decoration: underline;
            }
        }

        .discount-code {
            clear: both;
            margin: 25px 0 45px 0;

            a.apply {
                display: inline-block;
                margin: 0 0 0 20px;
                font-size: 18px;
                font-weight: 700;
                color: $brand-secondary;
            }
        }
    }

    hr {
        margin: 60px 0 35px 0;
        border: 2px solid $brand-primary;
        max-width: 1075px;
    }

    a.reactivate-account {
        display: inline-block;
        color: $brand-secondary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .actions {
        clear: both;
    }
}

@media (max-width: 1439px) {
    .subscription-settings {
        padding: 25px 20px;

        .plans {
            .plan {
                width: 120px;
                margin: 0 20px 25px 0;
            }
        }

        p {
            width: 90%;
        }

        hr {
            max-width: 90%;
        }

        form {
            &.cancel-account, &.delete-account {
                span {
                    width: 90%;
                }

                .cancel {
                    position: static;
                }
            }
        }
    }
}
