@import '../../styles/vars';

.survey {
    width: calc(100vw - 367px) !important;
    background-image: url('../../images/backgrounds/survey.jpg');
    background-size: cover;

    .intro {
        width: 62.5%;
        max-width: 675px;
        height: 440px;
        background-color: #F4F6F8;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        text-align: center;
        padding: 25px 40px 40px 40px;
        position: relative;
        margin: 140px auto;
        display: flex;
        flex-direction: column;

        .close-icon {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        h1 {
            margin: 0 0 20px 0;
        }

        h2 {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin: 0;
        }

        ol {
            width: 90%;
            list-style-type: none;
            background: $white;
            border-radius: 10px;
            padding: 32px 38px 33px 37px;
            margin: 30px 0;

            li {
                font-size: 16px;
                line-height: 19px;
                color: $brand-primary;
                margin: 0 0 50px 0;

                &:last-child {
                    margin: 0;
                }
            }
        }

        button.primary {
            align-self: flex-end;
        }
    }

    .survey-step {
        width: 90%;
        max-width: 1000px;
        margin: 55px auto;
        background-color: $white;
        border-radius: 10px;
        padding: 45px 30px 30px 30px;
        display: none;

        &.visible {
            display: flex;
            flex-direction: column;
        }

        .progress {
            width: 100%;
            margin: 0 0 50px 0;

            .progress-bar {
                margin: 0 0 10px 0;
            }

            .progress-details {
                span {
                    display: none;
                }
            }
        }

        .step-num {
            display: block;
            margin: 0 0 20px 0;
        }

        h1 {
            margin: 0 0 30px 0;

            &.center {
                width: 60%;
                text-align: center;
                margin: 80px auto;
            }
        }

        .MuiFormControl-root {
            margin: 0 0 70px 30px;
            width: 100%;

            .MuiButtonBase-root {
                color: $brand-secondary;

                .MuiSvgIcon-root {
                    width: 15px;
                    height: 15px;
                }
            }

            .MuiTypography-root {
                font-family: 'Sen';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: $brand-primary;
            }
        }

        .actions {
            align-self: flex-end;

            .back {
                color: $brand-secondary;
                margin: 0 50px 0 0;
            }
        }

        .logo {
            display: block;
            margin: 0 auto;
            width: 125px;
            height: 125px;
        }
    }
}

@media (max-width: 1439px) {
    .survey {
        width: 100% !important;

        .intro, .survey-step {
            width: calc(100% - 40px);
            height: auto;
            margin: 25px 20px;
            padding: 0;
            background-color: $white;

            img.close-icon {
                width: 25px;
                height: 25px;
            }

            h1 {
                margin: 50px 20px 0 20px;
            }

            h2 {
                font-size: 14px;
                line-height: 17px;
                margin: 10px 20px 15px 20px;
            }

            ol {
                width: 93%;
                margin: 0 10px;
                padding: 0;
                background-color: #F4F6F8;

                li {
                    font-size: 14px;
                    line-height: 17px;
                    margin: 20px 15px;

                    &:last-child {
                        margin: 20px 15px;
                    }
                }
            }

            button.primary {
                align-self: center;
                margin: 30px auto;
            }
        }

        .survey-step {
            .progress {
                margin: 50px 15px 0 15px;
                width: 88%;

                .progress-details {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;

                    label {
                        span {
                            color: $brand-secondary;
                        }
                    }
                    span {
                        display: inline;
                        color: $brand-primary;
                        margin: 0;
                    }
                }
            }

            span.step-num {
                display: none;
            }

            h1 {
                margin: 20px 10px 25px 10px;
                background-color: #F4F6F8;
                border-radius: 10px;
                padding: 15px;
                font-size: 18px;
                line-height: 22px;
                text-align: center;

                &.final {
                    background-color: $white;
                }
            }

            .MuiFormControl-root {
                width: 85%;
                margin: 0 0 50px 30px;

                .MuiTypography-root {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .actions {
                width: 110px;
                height: 40px;
                margin: 0 auto 30px auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .arrow {
                    width: 40px;
                    height: 40px;
                    font-size: 29px;
                    cursor: pointer;
                    background-color: $brand-primary;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                    border-radius: 50%;
                    color: $brand-tertiary;
                    display: flex;
                    justify-content: center;

                    &.back {
                        margin-right: 30px;
                    }

                    &.disabled {
                        background-color: $white;
                        cursor: default;
                    }
                }
            }

            img.logo {
                margin: 30px auto;
            }
        }
    }
}
