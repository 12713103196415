@import '../../styles/vars';

.mark-complete {
    width: 145px;
    height: 20px;
    padding: 5px 10px;
    border: 2px solid $white;
    border-radius: 5px;
    background-color: $white;
    color: $brand-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .MuiButtonBase-root {
        padding: 0;
        color: $brand-primary !important;
    }

    label {
        font-size: 16px;
        font-family: "Jost";
        cursor: pointer;
    }

    &:hover {
        border: 2px solid $brand-secondary;
        color: $brand-secondary;

        .MuiButtonBase-root {
            color: $brand-secondary !important;
        }
    }

    &.completed {
        border: 2px solid $brand-secondary;
        color: $white;
        background-color: $brand-secondary;

        .MuiButtonBase-root {
            color: $white !important;
        }
    }
}
