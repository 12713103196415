@import '../../styles/vars';

.footer {
    background: $brand-secondary;
    background-image: linear-gradient(125.82deg, $brand-secondary 25.09%, rgba(64, 183, 176, 0) 92.61%), url('../../images/backgrounds/footer.jpg');
    background-size: 1100px 525px;
    background-position: right;
    background-repeat: no-repeat;

    section {
        width: 75%;
        max-width: 1200px;
        height: 380px;
        margin: 80px 0 65px 280px;
        display: flex;
        justify-content: space-between;
        position: relative;

        .footer-section {
            width: 33%;
            height: 225px;
            margin-right: 125px;

            h1 {
                color: $white;
            }

            h2 {
                color: $white;
            }

            p {
                color: $white;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }

            form {
                input[type=email] {
                    width: 280px;
                    height: 35px;
                    border: 1px solid $white;
                    border-radius: 5px;
                    background-color: $brand-secondary;
                    color: $white;
                    font-size: 16px;
                    line-height: 19px;
                    padding-left: 25px;

                    &:focus {
                        outline-width: 0;
                    }

                    &::placeholder {
                        color: $white;
                    }
                }

                input[type=submit] {
                    width: 110px;
                    height: 35px;
                    background-color: $white;
                    border: 1px solid $white;
                    border-radius: 5px;
                    color: $brand-secondary;
                    text-align: center;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 15px 0 0 0;
                    padding: 0 15px !important;
                }
            }

            ul {
                list-style-type: none;
                margin: 25px 0 0 0;
                padding: 0;

                li {
                    margin: 0 0 25px 0;

                    a {
                        font-size: 16px;
                        line-height: 19px;
                        color: $white;
                    }
                }
            }
        }

        span.copyright {
            position: absolute;
            bottom: 0;
            left: 0;
            color: $white;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

@media (max-width: 1439px) {
    .footer {
        background: $brand-secondary;
        background-image: linear-gradient(180deg, $brand-secondary 12%, rgba(64, 183, 176, 0) 75%), url('../../images/backgrounds/footer.jpg');
        background-size: 920px 600px;
        background-position: bottom left;
        background-repeat: no-repeat;

        section {
            width: auto;
            height: auto;
            margin: 120px 15px 30px 25px;
            display: block;

            .footer-section {
                width: 100%;
                height: auto;
                margin: 0 0 90px 0;

                h1 {
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 30px;
                    margin: 0;
                }

                h2 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23px;
                    text-transform: uppercase;
                }

                p {
                    margin: 7px 0 21px 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    width: 92%;
                }

                form {
                    input[type=email] {
                        width: 75%;
                        height: 25px;
                        border-radius: 20px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 7px 30px;
                    }

                    input[type=submit] {
                        width: 100px;
                        height: 40px;
                        border-radius: 20px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 0;
                    }
                }

                ul {
                    margin: 40px 0 0 0;

                    li {
                        margin: 0 0 40px 0;

                        a {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 23px;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            span.copyright {
                position: relative;
            }
        }
    }
}
